<template>
  <div id="app">
    <!-- <tabelData></tabelData> -->
    <router-view></router-view>
  </div>
</template>

<script>
import tabelData from "./components/table.vue";

export default {
  name: "App",
  components: {
    tabelData,
  }
};
</script>

<style scoped>
@import url("./assets/reset.css");

#app {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  /* padding: 50px 30px; */
  background: rgb(240, 242, 245);
}
</style>
