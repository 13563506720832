<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip
        class="item"
        effect="dark"
        content="显隐列"
        placement="top"
        v-if="columns"
      >
        <span class="column" @click="showColumn()">
          <el-button size="mini" circle icon="el-icon-menu" />
          自定义列
        </span>
      </el-tooltip>
    </el-row>

    <el-dialog :title="title" :visible.sync="open" append-to-body width="760px">
      <div slot="title">
        <img src="@/assets/icon/zdyl-icon.png" alt="" />
        <span>自定义列</span>
      </div>
      <div style="display: flex; justify-content: space-around">
        <div class="zdyl-dxk">
          <el-checkbox
            style=" margin-bottom: 20px;margin-right: 20px;"
            v-for="(item, index) in columns"
            :key="'left' + index"
            v-model="item.visible"
            @change="columnSel"
            >{{ item.label }}</el-checkbox
          >
        </div>
        <div class="zdyl-tzk">
          <draggable v-model="columnsA" animation="300" class="center">
            <div
              class="item-list"
              v-for="(item, index) in columnsA"
              :key="'right' + index"
              v-show="item.visible"
            >
              <img src="@/assets/icon/menu.png" alt="" /><span>{{
                item.label
              }}</span>
              <i
                class="el-icon-close del"
                @click.stop="setColumnsA(item.key)"
              ></i>
            </div>
          </draggable>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button @click="submitList" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
//导入draggable组件
import draggable from "vuedraggable";
export default {
  name: "NewRightToolbar",
  components: {
    draggable,
  },
  data() {
    return {
      // 显隐数据
      value: [],
      // 弹出层标题
      title: "显示/隐藏",
      // 是否显示弹出层
      open: false,
      //储存列
      columnsA: [],
      // storeColumns: {},
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
    },
    type: {
      type: String,
    },
  },

  methods: {
    // 点击删除事件
    setColumnsA(idx) {
      for (let i = 0; i < this.columnsA.length; i++) {
        if (this.columnsA[i].key == idx) {
          this.columnsA[i].visible = false;
        }
      }
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].key == idx) {
          this.columns[i].visible = false;
        }
      }
    },
    // 提交自定义列
    submitList() {
      let storeColumns = localStorage.getItem("NewstoreColumns")
        ? JSON.parse(localStorage.getItem("NewstoreColumns"))
        : {};
      if (this.type == "dataInfo") {
        storeColumns.dataInfo = this.columnsA;
      }
      window.localStorage.setItem("NewstoreColumns", JSON.stringify(storeColumns));
      this.open = false;
      this.$emit("getColumn", this.columnsA);
    },
    columnSel() {
      this.columnsA = JSON.parse(JSON.stringify(this.columns));
    },
    // 搜索
    toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    // 刷新
    refresh() {
      this.$emit("queryTable");
    },
    // 右侧列表元素变化
    dataChange(data) {
      for (var item in this.columns) {
        const key = this.columns[item].key;
        this.columns[item].visible = !data.includes(key);
      }
      let storeColumns = localStorage.getItem("storeColumns")
        ? JSON.parse(localStorage.getItem("storeColumns"))
        : {};
      if (this.type == "dataInfo") {
        storeColumns.dataInfo = this.columns;
      } 
      window.localStorage.setItem("storeColumns", JSON.stringify(storeColumns));
    },
    // 打开显隐列dialog
    showColumn() {
      this.open = true;
      this.columnsA = JSON.parse(JSON.stringify(this.columns));
    },
    //鼠标移入数据时显示完整数据
    renderContent(h, option) {
      return <span title={option.label}>{option.label}</span>;
    },
  },
};
</script>
<style scoped>
::v-deep .el-transfer__button {
  border-radius: 50%;
  padding: 12px;
  display: block;
  margin-left: 0px;
}
::v-deep .el-transfer__button:first-child {
  margin-bottom: 10px;
}
.top-right-btn .column {
  color: #909399;
  font-size: 14px;
  cursor: pointer;
}
.top-right-btn {
  margin-right: 5px;
}
.zdyl-dxk {
  width: 440px;
  height: 400px;
  border-radius: 4px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.zdyl-tzk {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 260px;
  height: 400px;
  border-radius: 4px;
  overflow-y: scroll;
}
.item-list {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f3f4f6;
  display: flex;
  align-items: center;
  padding-left: 12px;
  position: relative;
}
.item-list img {
  margin-right: 16px;
  width: 16px;
}
.item-list:hover .del {
  display: block;
}
.del {
  display: none;
  position: absolute;
  right: 20px;
  top: 12px;
  cursor: pointer;
}
</style>
