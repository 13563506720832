<template>
  <div>
    <div class="erCode" v-show="ercodeShow" @click.stop="downerShow">
      <div class="content-text" style="width: 300px; height: 300px">
        <span class="mess_close" @click="downerShow"
          ><i class="el-icon-close"></i
        ></span>
        <div
          id="qrcode"
          ref="qrcode"
          @click="qrcode"
          style="width: 200px; height: 200px"
        ></div>
        <!-- <img src="../assets/image/qrcode.jpg" class="small-img" alt="" /> -->
        <span class="mess_attention">扫一扫，预览页面</span>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    ercodeId: String,
    ercodeShow: Boolean,
    preview:String
  },
  data() {
    return {
      // ercodeShow: false,
    };
  },
  watch: {
    // ercodeShow(val) {
      
      
    // },
    ercodeId(val){
      console.log(val);
      if (val) {
        this.qrcode();
      }
    }
  },
  methods: {
    downerShow() {
      this.$emit("closeCode");
    },
    
    qrcode() {
      console.log(window.location.origin)
      this.$refs.qrcode.innerHTML = "";
      let url
      if (this.preview) {
        url = window.location.origin + '/clue/showPage?id=' + this.ercodeId +'&type=预览';
      }else{
        url = window.location.origin + '/clue/showPage?id=' + this.ercodeId ;
      }
      let qrcode = new QRCode("qrcode", {
        width: 200, // 二维码宽度，单位像素
        height: 200, // 二维码高度，单位像素
        text: url, // 生成二维码的链接
      });
    },
  },
};
</script>

<style scoped>
.erCode {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 99999;
  margin-left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba($color: #000000, $alpha: 0.5); */
  background: rgba(0, 0, 0, 0.5);
}

.content-text {
  /* width: 450px;
    height: 450px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #fff;
  text-align: center;
  position: relative;
}
.mess_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

#qrcode {
  margin-top: 30px;
  width: 300px;
  height: 300px;
  background-color: aqua;
}
#qrcode canvas img {
  width: 300px !important;
  height: 300px !important;
}
.mess_attention {
  font-size: 16px;
  color: #3d3d3d;
  margin-top: 20px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}
.small-img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
</style>