<template>
  <div class="top-right-btn">
    <el-row>
      <!-- <el-tooltip class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'" placement="top">
        <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()" />
      </el-tooltip> -->
      <!-- <el-tooltip class="item" effect="dark" content="刷新" placement="top">
        <el-button size="mini" circle icon="el-icon-refresh" @click="refresh()" />
      </el-tooltip> -->
      <el-tooltip
        class="item"
        effect="dark"
        content="显隐列"
        placement="top"
        v-if="columns"
      >
        <span class="column" @click="showColumn()">
          <el-button size="mini" circle icon="el-icon-menu"> </el-button>
          自定义列
        </span>
      </el-tooltip>
    </el-row>
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-transfer
        :titles="['显示', '隐藏']"
        v-model="value"
        :data="columns"
        :render-content="renderContent"
        @change="dataChange"
      ></el-transfer>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "RightToolbar",
  data() {
    return {
      // 显隐数据
      value: [],
      // 弹出层标题
      title: "显示/隐藏",
      // 是否显示弹出层
      open: false,
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
    },
    type:{
      type:String
    }

  },
  created() {
    // 显隐列初始默认隐藏列
    for (let item in this.columns) {
      if (this.columns[item].visible === false) {
        this.value.push(parseInt(item));
      }
    }
  },
  methods: {
    // 搜索
    // toggleSearch() {
    //   this.$emit("update:showSearch", !this.showSearch);
    // },
    // 刷新
    // refresh() {
    //   this.$emit("queryTable");
    // },
    // 右侧列表元素变化
    dataChange(data) {
      for (var item in this.columns) {
        const key = this.columns[item].key;
        this.columns[item].visible = !data.includes(key);
      }
      let storeColumns = localStorage.getItem("storeColumns")
        ? JSON.parse(localStorage.getItem("storeColumns"))
        : {};
      if (this.type == "dataInfo") {
        storeColumns.dataInfo = this.columns;
      } else if (this.type == "ldy") {
        storeColumns.ldy = this.columns;
      }
      window.localStorage.setItem("storeColumns", JSON.stringify(storeColumns));
    },
    // 打开显隐列dialog
    showColumn() {
      this.open = true;
    },
    //鼠标移入数据时显示完整数据
    renderContent(h, option) {
      return <span title={option.label}>{option.label}</span>;
    },
  },
};
</script>
<style  scoped>
/*  <style lang="scss" scoped> */
.top-right-btn >>> .el-transfer__button {
  border-radius: 50%;
  padding: 12px;
  display: block;
  margin-left: 0px;
}
.top-right-btn >>> .el-transfer__button:first-child {
  margin-bottom: 10px;
}
.top-right-btn .column {
  color: #909399;
  font-size: 14px;
  cursor: pointer;
}
.top-right-btn {
  position: relative;
  float: right;
  margin-right: 5px;
}
</style>
