<template>
  <div class="box" v-if="!show">
    <!-- 搜索条件 -->
    <el-row :gutter="20">
      <el-col :span="24" :xs="24">
        <el-form
          :model="dataParams"
          label-width="98px"
          :inline="true"
          ref="queryForm"
        >
          <!-- <el-form-item label="落地页名称:" prop="ysLdyName">
            <el-input
              v-model.trim="dataParams.ysLdyName"
              placeholder="请输入"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item> -->
          <!-- <el-form-item
            label="是否电话拒绝以后关闭弹窗:"
            prop="isClosePopWind"
            label-width="190px"
          >
            <el-select
              v-model="dataParams.isClosePopWind"
              placeholder="请选择"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="dict in optionsList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item
            label="是否使用落地页:"
            prop="isUseLdy"
            label-width="120px"
          >
            <el-select
              v-model="dataParams.isUseLdy"
              placeholder="请选择"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="dict in optionsList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item
            label="是否只获取电话号码:"
            prop="onlyPhone"
            label-width="160px"
          >
            <el-select
              v-model="dataParams.onlyPhone"
              placeholder="请选择"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="dict in optionsList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item> -->

          <el-form-item label="客户类型:" prop="type">
            <el-select
              v-model="dataParams.type"
              placeholder="请选择"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="dict in khList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="数据ID:" prop="id">
            <el-input
              v-model.trim="dataParams.id"
              placeholder="请输入"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item> -->
          <el-form-item label="客户名称:" prop="customerName">
            <el-input
              v-model.trim="dataParams.customerName"
              placeholder="请输入"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item label="名称:" prop="ldyName">
            <el-input
              v-model.trim="dataParams.ldyName"
              placeholder="请输入"
              clearable
              size="small"
              style="width: 200px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="创建时间:" prop="daterange">
            <el-date-picker
              v-model="daterange"
              style="width: 280px"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @keyup.enter.native="handleQuery"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              round
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button
              icon="el-icon-refresh"
              size="small"
              round
              @click="resetQuery(true)"
              >重置</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              round
              @click="searchVisible = true"
              >高级搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-row :gutter="10" class="btn">
          <el-col :span="1.5"> </el-col>
          <el-col :span="1.5">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="AddInfo"
              >新增</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="primary"
              icon="el-icon-download"
              size="small"
              :disabled="!tableData.length"
              @click="onExport()"
              >导出</el-button
            >
          </el-col>

          <el-col :span="1.5">
            <el-button
              type="primary"
              icon="el-icon-document"
              size="small"
              @click="onOplog()"
              >操作日志</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="primary"
              icon="el-icon-edit-outline"
              size="small"
              @click="ldyManage()"
              >原生效果页</el-button
            >
          </el-col>

          <el-col :span="1.5">
            <el-button
              type="primary"
              icon="el-icon-folder-checked"
              size="small"
              @click="openHomepageCase()"
              >首页案例</el-button
            >
          </el-col>

          <el-col :span="1.5">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              :disabled="multiple"
              @click="delClick(listId)"
              >删除</el-button
            >
          </el-col>
          <!-- <right-toolbar
            @queryTable="getList"
            :columns="columns"
            type="dataInfo"
          ></right-toolbar> -->
          <new-right-toolbar
            style="float: right"
            @queryTable="getList"
            @getColumn="getColumn"
            :columns="columns"
            type="dataInfo"
          ></new-right-toolbar>
        </el-row>
      </el-col>
    </el-row>
    <!-- 落地页列表 -->
    <el-table
      v-loading="loading"
      v-if="dataListShow"
      :data="tableData"
      class="table-box"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        v-for="item in columnsA"
        :key="'list' + item.key"
        :label="item.label"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <!-- 数据ID -->
          <div v-if="item.value == 'id'">
            <span>{{ scope.row.id ? scope.row.id : "-" }}</span>
          </div>
          <!-- 名称 -->
          <div v-if="item.value == 'ldyName'">
            <span>{{ scope.row.ldyName ? scope.row.ldyName : "-" }}</span>
          </div>
          <!-- 客户类型 -->
          <div v-if="item.value == 'type'">
            <span>{{ scope.row.type == 1 ? "聚合通投" : "无" }}</span>
          </div>
          <!-- 客户名称 -->
          <div v-if="item.value == 'customerName'">
            <span>{{
              scope.row.customerName ? scope.row.customerName : "-"
            }}</span>
          </div>
          <!-- 宣传图 -->
          <div v-if="item.value == 'picUrl'">
            <el-image
              style="width: 120px"
              :src="
                scope.row.picUrl +
                '?x-oss-process=image/resize,m_fixed,h_120,w_120'
              "
              :title="scope.row.picUrl"
              fit="contain"
              :preview-src-list="[scope.row.picUrl]"
              v-if="scope.row.picUrl"
            ></el-image>
            <span v-else> 暂无 </span>
          </div>
          <!-- 是否只获取电话号码 -->
          <div v-if="item.value == 'onlyPhone'">
            <span v-if="scope.row.onlyPhone == 1" style="color: #008000a5"
              >是</span
            >
            <span v-else style="color: #ff0000a5">否</span>
          </div>
          <!-- 是否电话拒绝以后关闭弹窗 -->
          <div v-if="item.value == 'isClosePopWind'">
            <span v-if="scope.row.isClosePopWind == 1" style="color: #008000a5"
              >是</span
            >
            <span v-else style="color: #ff0000a5">否</span>
          </div>
          <!-- 是否使用原生效果页 -->
          <div v-if="item.value == 'isUseLdy'">
            <span v-if="scope.row.isUseLdy == 1" style="color: #008000a5"
              >是</span
            >
            <span v-else style="color: #ff0000a5">否</span>
          </div>
          <!-- 原生效果页名称 -->
          <div v-if="item.value == 'ysLdyName'">
            <span>{{ scope.row.ysLdyName ? scope.row.ysLdyName : "-" }}</span>
          </div>
          <!-- 访问量 -->
          <div v-if="item.value == 'pageviews'">
            <span>{{ scope.row.pageviews ? scope.row.pageviews : "0" }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        :show-overflow-tooltip="true"
        fixed
        prop="id"
        align="center"
        label="数据ID"
        v-if="columns[0].visible"
      ></el-table-column>
      <el-table-column
        prop="ldyName"
        align="center"
        label="名称"
        v-if="columns[1].visible"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.ldyName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        :show-overflow-tooltip="true"
        align="center"
        label="客户类型"
        v-if="columns[2].visible"
      >
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? "排期" : "竞价" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        align="center"
        label="客户名称"
        v-if="columns[3].visible"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.customerName }}
        </template>
      </el-table-column>

      <el-table-column
        prop="picUrl"
        align="center"
        label="宣传图"
        v-if="columns[4].visible"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 120px"
            :src="
              scope.row.picUrl +
              '?x-oss-process=image/resize,m_fixed,h_120,w_120'
            "
            :title="scope.row.picUrl"
            fit="contain"
            :preview-src-list="[scope.row.picUrl]"
            v-if="scope.row.picUrl"
          ></el-image>
          <span v-else> 暂无 </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="onlyPhone"
        :show-overflow-tooltip="true"
        align="center"
        label="是否只获取电话号码"
        v-if="columns[5].visible"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.onlyPhone == 1" style="color: #008000a5"
            >是</span
          >
          <span v-else style="color: #ff0000a5">否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isClosePopWind"
        :show-overflow-tooltip="true"
        align="center"
        label="是否电话拒绝以后关闭弹窗"
        v-if="columns[6].visible"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isClosePopWind == 1" style="color: #008000a5"
            >是</span
          >
          <span v-else style="color: #ff0000a5">否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isUseLdy"
        :show-overflow-tooltip="true"
        align="center"
        label="是否使用原生效果页"
        v-if="columns[7].visible"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isUseLdy == 1" style="color: #008000a5"
            >是</span
          >
          <span v-else style="color: #ff0000a5">否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="ysLdyName"
        :show-overflow-tooltip="true"
        align="center"
        label="原生效果页名称"
        v-if="columns[8].visible"
      >
      </el-table-column>
      <el-table-column
        prop="pageviews"
        :show-overflow-tooltip="true"
        align="center"
        label="访问量"
        v-if="columns[9].visible"
      >
      </el-table-column> -->
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="
              onClue(scope.row.id, scope.row.ldyName), (xsTabelBox = true)
            "
          >
            <div style="display: flex; align-items: center">
              <img
                src="@/assets/icon/xs-icon.png"
                alt=""
                style="width: 16px; margin-right: 2px"
              />线索
            </div>
          </el-button>
          <!-- <el-button
            type="text"
            size="small"
            
            ></el-button
          > -->
          <el-button size="mini" type="text" @click="goSetInfo(scope.row)">
            <div style="display: flex; align-items: center">
              <img
                src="@/assets/icon/bj-icon.png"
                alt=""
                style="width: 16px; margin-right: 2px"
              />编辑
            </div>
          </el-button>

          <!-- <el-button @click="goSetInfo(scope.row)" type="text" size="small"
            >修改</el-button
          > -->
          <el-popover
            placement="right"
            trigger="click"
            ref="more"
            popper-class="popover"
            @show="moreShow(scope.$index)"
            @hide="moreHide"
          >
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="primary"
                icon="el-icon-document-copy"
                @click="onCopy(scope.row.id, scope.row)"
                >复制</el-button
              >
            </el-row>
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="primary"
                icon="el-icon-postcard"
                @click="onPreviewBox(scope.row.id, scope.row)"
                >预览</el-button
              >
            </el-row>
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="primary"
                icon="el-icon-data-line"
                @click="openXxTableBox(scope.row.id, '初始化')"
                >详细报表</el-button
              >
            </el-row>
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="openSetVisit(scope.row)"
                >访问量</el-button
              >
            </el-row>
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="handlePage(scope.row)"
                >设置首页案例</el-button
              >
            </el-row>
            <el-row>
              <el-button
                class="btn-a"
                size="small"
                type="danger"
                icon="el-icon-close"
                @click="delClick(scope.row.id, scope.row)"
                v-if="scope.row.isDefault != 1"
                >删除</el-button
              >
            </el-row>
            <!-- <el-button
              slot="reference"
              type="text"
              size="small"
              style="margin-left: 10px"
              >更多
              <i
                :class="
                  moreShowOpen[scope.$index] == true
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-down'
                "
              ></i
            ></el-button> -->
            <el-button
              slot="reference"
              type="text"
              size="small"
              style="margin-left: 10px"
            >
              <div style="display: flex; align-items: center">
                <img
                  src="@/assets/icon/gd-icon.png"
                  alt=""
                  style="width: 16px"
                />更多
              </div>
            </el-button>
          </el-popover>
          <!-- <el-popover placement="right" trigger="click" popper-class="popover">
            <p >复制</p>
            <p @click="onPreviewBox(scope.row.id, scope.row)">预览</p>
            <p
              @click="delClick(scope.row.id)"
              v-if="scope.row.isDefault != 1"
              style="color: #f56c6c"
            >
              删除
            </p>
            <el-button
              slot="reference"
              type="text"
              size="small"
              style="margin-left: 10px"
              >更多 <i class="el-icon-arrow-down"></i
            ></el-button>
          </el-popover> -->

          <!-- <el-button
            type="text"
            size="small"
            @click="onCopy(scope.row.id, scope.row)"
            >复制</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="onPreviewBox(scope.row.id, scope.row)"
            >预览</el-button
          >
          <el-button
            v-if="scope.row.isDefault != 1"
            type="text"
            size="small"
            style="color: #f56c6c"
            @click="delClick(scope.row.id)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改访问量 -->
    <el-dialog
      :visible.sync="SetVisitBox"
      width="380px"
      :before-close="SetVisitBoxClose"
    >
      <div
        slot="title"
        style="display: flex; align-items: center; font-weight: 600"
      >
        <i
          class="el-icon-warning"
          style="font-size: 28px; color: #faad14; margin-right: 6px"
        ></i
        >访问量
      </div>
      <el-form
        :model="SetVisit"
        label-width="86px"
        ref="SetVisitRef"
        :rules="SetVisitRules"
      >
        <el-form-item label="访问量：" prop="num">
          <el-input
            v-model="SetVisit.num"
            placeholder="请输入"
            clearable
            size="small"
            style="width: 200px"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SetVisitBox = false">取 消</el-button>
        <el-button type="primary" @click="submitVisit">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="dataParams.num"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="dataParams.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 高级搜索弹出框 -->
    <el-dialog
      title="高级搜索"
      :visible.sync="searchVisible"
      width="920px"
      class="form_box"
      :close-on-click-modal="false"
    >
      <div slot="title" class="title_box">
        <img src="@/assets/icon/gjss.png" alt="" style="width: 20px" />
        <span style="margin-left: 2px; color: #0000ff; font-size: 14px"
          >高级搜索</span
        >
      </div>

      <el-form :model="dataParams" label-width="185px" ref="queryForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据ID：" prop="id">
              <el-input
                v-model.trim="dataParams.id"
                placeholder="请输入"
                clearable
                size="small"
                style="width: 200px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原生效果页名称：" prop="ysLdyName">
              <el-input
                v-model.trim="dataParams.ysLdyName"
                placeholder="请输入"
                clearable
                size="small"
                style="width: 200px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户类型：" prop="type">
              <el-select
                v-model="dataParams.type"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 200px"
              >
                <el-option
                  v-for="dict in khList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称：" prop="customerName">
              <el-input
                v-model.trim="dataParams.customerName"
                placeholder="请输入"
                clearable
                size="small"
                style="width: 200px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称：" prop="ldyName">
              <el-input
                v-model.trim="dataParams.ldyName"
                placeholder="请输入"
                clearable
                size="small"
                style="width: 200px"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否使用原生效果页：" prop="isUseLdy">
              <el-select
                v-model="dataParams.isUseLdy"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 200px"
              >
                <el-option
                  v-for="dict in optionsList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="是否只获取电话号码：" prop="onlyPhone">
              <el-select
                v-model="dataParams.onlyPhone"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 200px"
              >
                <el-option
                  v-for="dict in optionsList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="是否电话拒绝以后关闭弹窗："
              prop="isClosePopWind"
            >
              <el-select
                v-model="dataParams.isClosePopWind"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 200px"
              >
                <el-option
                  v-for="dict in optionsList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="创建时间：" prop="daterange">
              <el-date-picker
                v-model="daterange"
                style="width: 280px"
                type="daterange"
                align="right"
                size="small"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetQuery(false)">重 置</el-button>
        <el-button type="primary" @click="handleQuery()">搜 索</el-button>
      </div>
    </el-dialog>

    <!-- 新增/修改弹出框 -->
    <el-dialog
      :visible.sync="addInfoBox"
      width="920px"
      class="form_box"
      :close-on-click-modal="false"
      @close="cancel"
    >
      <div slot="title" class="title_box">
        <img src="@/assets/icon/xz-icon.png" alt="" />
        <span style="font-size: 14px; color: #00f">{{ title }}</span>
      </div>

      <el-form
        :model="infoData"
        label-width="164px"
        ref="infoData"
        :rules="InfoRules"
        label-positio="left"
      >
        <el-form-item label="客户类型：" prop="type">
          <!-- <el-tooltip class="item" effect="dark" content="必选" placement="top">
            <i class="el-icon-warning-outline" style="cursor: pointer"></i>
          </el-tooltip> -->

          <el-select
            v-model="infoData.type"
            placeholder="请选择"
            @change="changeKhList"
            clearable
            size="small"
            style="width: 480px"
          >
            <el-option
              v-for="dict in khList"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="客户名称：" prop="customerId">
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="先选择客户类型，然后选择对应的客户"
            placement="top"
          >
            <i class="el-icon-warning-outline" style="cursor: pointer"></i>
          </el-tooltip> -->

          <el-select
            v-model="infoData.customerId"
            filterable
            placeholder="请选择"
            clearable
            size="small"
            style="width: 480px"
            :disabled="!addkhList.length"
            @change="changeKlName"
          >
            <el-option
              v-for="dict in addkhList"
              :key="dict.id"
              :label="dict.name + '（' + dict.id + '）'"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="名称：" prop="ldyName">
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="必填，不能够超过50个字符"
            placement="top"
          >
            <i class="el-icon-warning-outline" style="cursor: pointer"></i>
          </el-tooltip> -->

          <el-input
            v-model="infoData.ldyName"
            maxlength="50"
            placeholder="请输入"
            clearable
            size="small"
            style="width: 480px"
          />
        </el-form-item>

        <el-form-item label="联系电话：" prop="phone">
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="选填，填写了小程序左下角有咨询按钮，未填写就没有"
            placement="top"
          >
            <i class="el-icon-warning-outline" style="cursor: pointer"></i>
          </el-tooltip> -->

          <el-input
            v-model="infoData.phone"
            placeholder="请输入"
            clearable
            size="small"
            maxlength="12"
            style="width: 480px"
          />
        </el-form-item>

        <el-form-item label="弹框按钮名称：" prop="tkButtonId">
          <el-select
            v-model="infoData.tkButtonId"
            filterable
            placeholder="请选择"
            clearable
            size="small"
            style="width: 480px"
          >
            <el-option
              v-for="dict in anList"
              :key="dict.id"
              :label="dict.label"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="是否显示底部按钮：" label-width="195px">
          <!-- <el-radio v-model="infoData.radios" :label="0">备选项</el-radio>
          <el-radio v-model="infoData.radios" :label="1">备选项</el-radio> -->
          <el-switch
            v-model="infoData.hasDbButton"
            :active-value="1"
            :inactive-value="0"
            class="tablescope"
            inactive-text="否"
            active-text="是"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          label="底部按钮名称："
          prop="dbButtonId"
          v-show="infoData.hasDbButton == 1"
        >
          <el-select
            v-model="infoData.dbButtonId"
            filterable
            placeholder="请选择"
            clearable
            size="small"
            style="width: 480px"
          >
            <el-option
              v-for="dict in anList"
              :key="dict.id"
              :label="dict.label"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="提交按钮名称：" prop="tjButtonId">
          <el-select
            v-model="infoData.tjButtonId"
            filterable
            placeholder="请选择"
            clearable
            size="small"
            style="width: 480px"
          >
            <el-option
              v-for="dict in anList"
              :key="dict.id"
              :label="dict.label"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="是否只获取电话号码："
          label-width="195px"
          prop="onlyPhone"
        >
          <el-switch
            v-model="infoData.onlyPhone"
            :active-value="1"
            :inactive-value="0"
            class="tablescope"
            inactive-text="否"
            active-text="是"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          label="是否使用原生效果展示："
          label-width="195px"
          prop="isUseLdy"
        >
          <el-switch
            v-model="infoData.isUseLdy"
            :active-value="1"
            :inactive-value="0"
            class="tablescope"
            inactive-text="否"
            active-text="是"
            @change="handleChangeSwitch"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          label="是否电话拒绝以后关闭弹窗："
          prop="isClosePopWind"
          label-width="195px"
        >
          <el-switch
            v-model="infoData.isClosePopWind"
            :active-value="1"
            :inactive-value="0"
            class="tablescope"
            inactive-text="否"
            active-text="是"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          label="原生效果页面："
          prop="ldyDetailId"
          v-if="infoData.isUseLdy == 1"
          :rules="{
            required: true,
            message: '请选择原生效果页面',
            trigger: 'change',
          }"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="必选,请选择客户名称再选择原生效果页面"
            placement="top"
          >
            <i class="el-icon-warning-outline" style="cursor: pointer"></i>
          </el-tooltip>

          <el-select
            v-model="infoData.ldyDetailId"
            filterable
            placeholder="请选择"
            clearable
            size="small"
            style="width: 240px"
            @focus="handleGetldyList()"
            :disabled="!infoData.customerId"
          >
            <el-option
              v-for="dict in ldyUrlList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
          <span class="ldyadd">
            <!-- <span @click="handleAddLdy()">新增</span> -->
            <el-button size="mini" type="primary" @click="handleAddLdy()"
              >新增</el-button
            >
            <el-button size="mini" type="primary" @click="handleGetldyList()"
              >刷新</el-button
            >
            <!-- <span @click="handleGetldyList()" style="margin-left: 10px">刷新</span> -->
          </span>
        </el-form-item>
        <!-- </el-col>
        </el-row> -->
        <el-form-item label="宣传图：" prop="picUrl">
          <!-- :headers="myHeaders" -->
          <el-upload
            ref="lpsyt"
            :action="upImgURL"
            list-type="picture-card"
            :file-list="ImgList"
            :on-success="imageListOK"
            :before-upload="imageUpBef"
            :on-change="ImgChange"
            :on-remove="handleRemove"
            accept=".png, .jpg, .jpeg, .GNP, .JPG, .JPEG"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png/jpeg文件，且不超过10M
            </div>
            <div
              class="el-upload__tip"
              slot="tip"
              style="color: red; line-height: 10px"
            >
              图片建议尺寸为600*600
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="小程序转发头像：" prop="imagePicUrl">
          <!-- :headers="myHeaders" -->
          <el-upload
            ref="lpsytTwo"
            :action="upImgURL"
            list-type="picture-card"
            :file-list="HeadImageList"
            :on-success="headImageListOK"
            :before-upload="headImageUpBef"
            :on-change="HeadImgChange"
            :on-remove="headhandleRemove"
            accept=".png, .jpg, .jpeg, .GNP, .JPG, .JPEG"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png/jpeg文件，且不超过20kb
            </div>
            <div
              class="el-upload__tip"
              slot="tip"
              style="color: red; line-height: 10px"
            >
              头像建议比例为1:1
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="普通效果内容："
          prop="content"
          v-if="infoData.isUseLdy == 0"
          :rules="{
            required: true,
            message: '请填写普通效果内容',
            trigger: 'change',
          }"
        >
          <Editor
            v-model="infoData.content"
            :uploadUrl="upImgURL"
            height="450"
          ></Editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="onAddInfo()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 线索弹出框 -->
    <el-dialog
      title="线索"
      :visible.sync="xsTabelBox"
      width="1200px"
      :close-on-click-modal="false"
    >
      <div slot="title" class="title_box">
        <img src="@/assets/icon/xs-icon.png" alt="" />
        <span style="font-size: 14px; color: #00f">线索</span>
      </div>
      <!-- <el-row v-if="dataName">
        <el-col>
          <p style="font-size: 18px; color: #303133; font-weight: 650">
            数据名称：{{ dataName }}
          </p>
        </el-col>
      </el-row> -->
      <p style="margin-top: -15px; color: #00000072">{{ dataName }} / 线索</p>

      <el-row :gutter="20">
        <el-col :span="1.5">
          <el-button
            type="primary"
            plain
            icon="el-icon-download"
            size="mini"
            :disabled="!xsData.length"
            @click="onxsExport()"
            >导出</el-button
          >
        </el-col>

        <el-col :span="1.5">
          <el-button
            type="primary"
            plain
            icon="el-icon-upload2"
            size="mini"
            @click="handleImport()"
            >导入</el-button
          >
        </el-col>

        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="xsmultiple"
            @click="xsdelClick(xsListId)"
            >删除</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="xsData"
        border
        stripe
        class="table-box"
        @selection-change="xsSelectionChange"
        style="margin-top: 25px"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          property="nickName"
          align="center"
          label="昵称"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.nickName">{{ scope.row.nickName }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          property="name"
          align="center"
          label="姓名"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.name">{{ scope.row.name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :show-overflow-tooltip="true"
          property="city"
          align="center"
          label="城市"
        ></el-table-column> -->
        <!-- <el-table-column
          :show-overflow-tooltip="true"
          property="province"
          align="center"
          label="省份"
        ></el-table-column> -->
        <!-- <el-table-column
          :show-overflow-tooltip="true"
          property="country"
          align="center"
          label="国家"
        ></el-table-column> -->
        <!-- <el-table-column
          :show-overflow-tooltip="true"
          property="sex"
          align="center"
          label="性别"
        ></el-table-column> -->
        <el-table-column prop="portrait" label="头像" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.portrait"
              :src="scope.row.portrait"
              style="width: 32px"
              fit="contain"
              :preview-src-list="[scope.row.portrait]"
            ></el-image>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          property="phone"
          width="150px"
          align="center"
          label="电话号码"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          property="createDate"
          width="180px"
          align="center"
          label="线索产生时间"
        ></el-table-column>
        <el-table-column property="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #f56c6c"
              @click="xsdelClick(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="xsSizeChange"
        @current-change="xsCurrentChange"
        :current-page="xsParams.num"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="xsParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="xsDataTotal"
      >
      </el-pagination>

      <!-- 线索导入弹出框 -->

      <el-dialog
        title="数据导入"
        :visible.sync="importVisible"
        width="600px"
        append-to-body
        @close="fileCancel"
        :close-on-click-modal="false"
      >
        <el-form>
          <el-form-item
            label="导入文件："
            :rules="{
              required: true,
              message: '请上传文件',
              trigger: 'change',
            }"
          >
            <el-upload
              ref="upload"
              class="upload-demo"
              :limit="1"
              drag
              :action="uploadFile"
              :on-success="handleSuccess"
              :before-upload="handleBefore"
              :on-progress="hanldeProgress"
              :auto-upload="false"
              :data="otherData"
              accept=".xls,.xlsx"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传xls/xlsx文件，且不超过20M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleTemplate"
              >下载导入模板</el-button
            >
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="fileCancel">关 闭</el-button>
          <el-button type="primary" @click="submitFileForm">导 入</el-button>
        </div>

        <!-- 进度条弹出框 -->
        <el-dialog
          width="350px"
          title="正在上传"
          :visible.sync="progressOpen"
          append-to-body
          :close-on-click-modal="false"
        >
          <el-progress :percentage="percent" color="#409eff"></el-progress>
        </el-dialog>
      </el-dialog>
    </el-dialog>
    <!-- 复制弹出框 -->
    <el-dialog
      title="复制"
      :visible.sync="copyBox"
      width="487px"
      :close-on-click-modal="false"
    >
      <div slot="title" class="title_box">
        <img src="@/assets/icon/fz-icon.png" alt="" />
        <span style="font-size: 14px; color: #00f">复制</span>
      </div>
      <p style="margin-top: -15px; color: #00000072">{{ dataName }}</p>

      <el-form>
        <el-form-item label="投放类型:" label-width="118px">
          <el-select size="small" v-model="putTypeSel" placeholder="请选择">
            <el-option
              v-for="item in putType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放平台:" label-width="118px">
          <el-select
            size="small"
            v-model="putPlatSel"
            placeholder="请选择"
            @change="handlePutPlat"
          >
            <el-option
              v-for="item in putPlatform"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="小程序:"
          label-width="118px"
          v-if="putTypeSel == '小程序'"
        >
          <el-select size="small" v-model="xcxTypeSel" placeholder="请选择">
            <el-option
              v-for="item in xcxType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="小程序原始ID:"
          label-width="118px"
          v-if="putTypeSel == '小程序'"
        >
          <span>{{ xcxTypeSel }}</span>
        </el-form-item> -->

        <el-form-item
          label="原始ID/APPID:"
          label-width="118px"
          v-if="putTypeSel == '小程序'"
        >
          <span>{{ xcxTypeSel }}</span>
        </el-form-item>
        <el-form-item label="启动页面:" label-width="118px">
          <span v-if="putTypeSel == '小程序'"
            >pages/index/index?id={{ copyId }}</span
          >
          <span v-else
            >https://miniapp.shushitong.cn/clue/showPage?id={{ copyId }}</span
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyBox = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 操作日志弹出框 -->
    <el-dialog
      title="操作日志"
      :visible.sync="oplogBox"
      :close-on-click-modal="false"
      width="800px"
    >
      <div class="czCap">
        <span>客户名称: </span>
        <el-input
          v-model="cz.operatorName"
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="czhandleQuery"
        />
        <el-button
          class="sea-btn"
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="czhandleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="czresetQuery"
          >重置</el-button
        >
      </div>
      <el-table :data="oplogData" border stripe class="table-box">
        <el-table-column
          property="operator"
          :show-overflow-tooltip="true"
          label="操作人IP"
        ></el-table-column>
        <el-table-column
          property="operatorContent"
          :show-overflow-tooltip="true"
          label="操作人内容"
          width="200"
        >
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="opSqlBox(scope.row.operatorContent)"
              >{{ scope.row.operatorContent }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          property="operatorName"
          :show-overflow-tooltip="true"
          label="客户名称"
        >
        </el-table-column>
        <el-table-column
          property="operatorDate"
          :show-overflow-tooltip="true"
          label="操作时间"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="czSizeChange"
        @current-change="czCurrentChange"
        :current-page="cz.num"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="cz.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="oplogTotal"
      >
      </el-pagination>
    </el-dialog>
    <!-- 预览弹出框 -->
    <el-dialog
      title="预览"
      :visible.sync="xcxTypeYlBox"
      width="487px"
      :close-on-click-modal="false"
    >
      <el-row v-if="dataName">
        <el-col>
          <p style="font-size: 18px; color: #303133; font-weight: 650">
            数据名称：{{ dataName }}
          </p>
        </el-col>
      </el-row>

      <el-form>
        <el-form-item label="投放类型:" label-width="118px">
          <el-select size="small" v-model="perPutTypeSel" placeholder="请选择">
            <el-option
              v-for="item in putType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="小程序类型:"
          label-width="118px"
          v-if="perPutTypeSel == '小程序'"
        >
          <el-select size="small" v-model="xcxTypeYl" placeholder="请选择">
            <el-option
              v-for="item in ylXcxType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="xcxTypeYlBox = false">关 闭</el-button>
        <el-button type="primary" @click="prePage">预 览</el-button>
      </div>
    </el-dialog>
    <!-- 详细报表 -->
    <el-dialog
      title="详细报表"
      :visible.sync="xxTableBox"
      width="1200px"
      :close-on-click-modal="false"
      :before-close="closeBox"
    >
      <div>
        统计时间：
        <el-date-picker
          size="small"
          v-model="Time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="changeTime"
        >
        </el-date-picker>
      </div>
      <div
        ref="myChart"
        :style="{ width: '1200px', height: '600px', marginTop: '20px' }"
        v-show="isDataList"
      ></div>
      <div v-show="!isDataList" class="zwsj">暂无数据</div>
    </el-dialog>
    <!-- 预览二维码--小程序 -->
    <div
      class="popCon"
      @click="preCode = false"
      v-if="preCode"
      v-loading="loadinga"
    >
      <div class="codeBox" v-if="!loadinga">
        <div class="close">
          <i class="el-icon-close"></i>
        </div>
        <img :src="qrCode" alt="" />
        <span class="mess_attention">扫一扫，预览页面</span>
      </div>
    </div>
    <!-- 删除确认弹出框 -->
    <el-dialog
      :visible.sync="delOpen"
      width="360px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :show-close="false"
      class="del_box"
    >
      <div slot="title" class="title_box">
        <img src="@/assets/icon/delete.png" alt="" style="width: 20px" />
        <span style="margin-left: 2px; color: #0000ff; font-size: 14px"
          >是否确认</span
        >
      </div>

      <div v-if="isUse">
        <span style="color: #ff0000">信息中包含线索信息，是否</span
        ><span style="color: #999999">确认删除数据名称是</span
        ><span style="color: #00cc00">{{ listName.join(",") }}</span
        >?
      </div>

      <div v-else>
        <span style="color: #f00">是否</span><span>确认删除数据名称是</span
        ><span style="color: #00cc00">确认删除数据名称是</span
        ><span style="color: #00cc00">{{ listName.join(",") }}</span> ?
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="delOpen = false">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="btnLoading"
          @click="delRes"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 预览二维码--原生页面 -->
    <ercode
      :ercodeShow="isAction"
      :ercodeId="ylId"
      @closeCode="closeCode"
    ></ercode>

    <!-- 设置首页案例 -->
    <set-home-case ref="setHomeCase"></set-home-case>
  </div>
</template>

<script>
// 引入富文本组件
import Editor from "./Editor.vue";
import ercode from "../components/ercode.vue";
import setHomeCase from "@/components/setHomeCase.vue";
import { parseTime } from "../assets/js/formatTime";
export default {
  components: {
    Editor,
    ercode,
    setHomeCase,
  },
  watch: {
    xcxTypeYl(val) {
      // 选择的小程序改变则从新获取二维码
      this.getQrCode();
    },
  },
  data() {
    var fwlRef = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/;
      if (value == "") {
        callback(new Error("请输入访问量"));
      } else if (!reg.test(value)) {
        callback(new Error("请填写大于0的正整数)"));
      } else {
        callback();
      }
    };
    return {
      // 复制 -- 投放平台的值
      putPlatSel: "微信",
      delIds: "",
      // 是否有关联
      isUse: false,
      // 删除对话框
      delOpen: false,
      // 按钮等待
      btnLoading: false,
      // 循环的列表
      columnsA: [],
      // 更多按钮是否点击
      moreShowOpen: [],
      //数据ID 用于弹出框展示
      dataID: null,
      //名称 用于弹出框展示
      dataName: null,
      //查询条件选择列表--是否电话拒绝以后关闭弹窗/是否使用落地页/是否只获取电话号码
      optionsList: [
        {
          label: "全部",
          value: "-1",
        },
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "0",
        },
      ],
      //预览二维码弹出层--小程序
      preCode: false,
      //是否显示分享二维码--原生页面
      isAction: false,
      //预览二维码遮罩层
      loadinga: false,
      //时间范围
      daterange: null,
      // 二维码图片
      qrCode: undefined,
      // 小程序预览对话框
      xcxTypeYlBox: false,
      // 预览小程序
      xcxTypeYl: "1",
      // 预览小程序ID
      ylId: undefined,
      // 预览小程序类型
      ylXcxType: [
        {
          label: "数实通科技",
          value: "1",
        },
        {
          label: "数实通小站",
          value: "2",
        },

      ],
      // 小程序类型
      xcxType: [
        {
          label: "数实通科技",
          value: "gh_e111da8f33b8",
        },
        {
          label: "数实通小站",
          value: "gh_d4afa952345c",
        },
      ],

      // 小程序类型选中的值
      xcxTypeSel: "",
      //投放类型下拉值--复制
      putType: [
        {
          label: "小程序",
          value: "小程序",
        },
        // {
        //   label: "原生页面",
        //   value: "原生页面",
        // },
      ],
      // 投放平台下拉值
      putPlatform: [
        {
          label: "微信",
          value: "微信",
        },
        {
          label: "抖音",
          value: "抖音",
        },
      ],
      // 投放类型选中的值--复制
      putTypeSel: "小程序",
      // 投放类型选中的值--预览
      perPutTypeSel: "小程序",
      // 操作日志页面显示
      oplogBox: false,
      // 操作日志数据
      oplogData: [],
      // 操作日志总条数
      oplogTotal: 0,
      // 按钮列表
      anList: [],
      // 客户名称列表
      addkhList: [],
      //落地页列表
      ldyUrlList: [],
      // 选中的线索行
      xsListId: [],
      // 选中的行
      listId: [],
      // 选中行的名称
      listName: [],
      // 线索导出多选
      xsmultiple: true,
      //非多个禁用
      multiple: true,
      //非多个禁用 线索弹出框
      multiple1: true,

      // 上传图片视频接口
      upImgURL: window.baseURL + "/ldy/upload",
      //是否显示高级搜索弹出框
      searchVisible: false,
      // 搜索条件
      dataParams: {
        // 起始页
        num: 1,
        // 页大小
        size: 10,
        // 客户类型0排期1竞价
        type: undefined,
        // 数据ID
        id: undefined,
        // 客户名称
        customerName: undefined,
        // 名称
        ldyName: undefined,
        //起始时间
        startTime: undefined,
        //结束时间
        endTime: undefined,
        // 原生落地页名称
        ysLdyName: undefined,
        // 是否电话拒绝以后关闭弹窗 1是 0否 -1全部
        isClosePopWind: undefined,
        //是否使用落地页1是0否-1全部
        isUseLdy: undefined,
        //是否只获取电话号码1是0否-1全部
        onlyPhone: undefined,
      },
      // 操作日志搜索条件
      cz: {
        operatorName: undefined,
        size: 10,
        num: 1,
      },
      // 标题
      title: "新增",
      // 新增对话框显示控制
      addInfoBox: false,
      // 新增或修改数据
      infoData: {
        // 是否显示底部按钮
        hasDbButton: 1,
        // 落地页内容
        content: "",
        // 客户id
        customerId: undefined,
        // 客户名称
        customerName: undefined,
        // 落地页名称
        ldyName: undefined,
        // 电话
        phone: undefined,
        // 落地页宣传图
        picUrl: undefined,
        // 转发头像
        headImg: "",
        // 弹框按钮
        tkButtonId: undefined,
        // 底部按钮
        dbButtonId: undefined,
        // 提交按钮
        tjButtonId: undefined,
        // 客户类型
        type: undefined,
        // 是否只获取手机号
        onlyPhone: 1,
        // 是否电话拒绝以后关闭弹窗
        isClosePopWind: 0,
        //是否使用落地页1是0否
        isUseLdy: 1,
        //落地页详情
        ldyDetailId: undefined,
      },
      // 客户类型列表
      khList: [
        {
          value: 1,
          label: "聚合通投",
        },
        {
          value: 2,
          label: "无",
        },
      ],
      // 新增的图片列表
      ImgList: [],
      // 新增的转发头像
      HeadImageList: [],
      // 线索对话框
      xsTabelBox: false,
      // 遮罩
      loading: false,
      // 总数
      total: 0,
      // 列表数据
      tableData: [],
      // 线索列表数据
      xsData: [],
      // 总数
      xsDataTotal: 0,
      // 线索搜索条件
      xsParams: {
        // 起始页
        num: 1,
        // 页大小
        size: 10,
        // id
        ids: undefined,
      },
      // 复制弹出框
      copyBox: false,
      // 落地页id
      copyId: undefined,
      // 修改访问量对话框
      SetVisitBox: false,
      // 修改访问量
      SetVisit: {
        num: "",
        ldyId: "",
      },
      SetVisitRules: {
        num: [
          {
            required: true,
            validator: fwlRef,
            trigger: "blur",
          },
        ],
      },
      // 新增信息校验
      InfoRules: {
        type: [
          { required: true, message: "请选择客户类型", trigger: "change" },
        ],
        customerId: [
          { required: true, message: "请选择客户名称", trigger: "change" },
        ],
        ldyName: [
          {
            required: true,
            message: "请输入原生效果页名称",
            trigger: "change",
          },
        ],
        // phone: [
        //   {
        //     required: true,
        //     message: "请输入落地页联系电话",
        //     trigger: "change",
        //   },
        // ],
        // picUrl: [
        //   { required: true, message: "请上传落地页宣传图", trigger: "change" },
        // ],
        tkButtonId: [
          { required: true, message: "请选择弹框按钮文案", trigger: "change" },
        ],
        dbButtonId: [
          { required: true, message: "请选择底部按钮文案", trigger: "change" },
        ],
        tjButtonId: [
          { required: true, message: "请选择提交按钮文案", trigger: "change" },
        ],
        // content: [
        //   { required: true, message: "请填写落地页内容", trigger: "change" },
        // ],
        // ldyDetailId: [
        //   { required: true, message: "请选择落地页", trigger: "change" },
        // ],
        onlyPhone: [
          {
            required: true,
            message: "请选择是否只获取电话号码",
            trigger: "change",
          },
        ],
        isClosePopWind: [
          {
            required: true,
            message: "请选择是否电话拒绝以后关闭弹窗",
            trigger: "change",
          },
        ],
        isUseLdy: [
          {
            required: true,
            message: "请选择是否使用落地页",
            trigger: "change",
          },
        ],
        // content: [
        //   { required: true, message: "请落地页内容", trigger: "change" },
        // ],
      },
      // 小程序原始ID
      xcxysId: undefined,
      //日期快捷选择范围
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 列信息
      columns: [
        { key: 0, label: `数据ID`, value: "id", visible: false },
        { key: 1, label: `名称`, value: "ldyName", visible: true },
        { key: 2, label: `客户类型`, value: "type", visible: true },
        { key: 3, label: `客户名称`, value: "customerName", visible: true },
        { key: 4, label: `宣传图`, value: "picUrl", visible: true },
        {
          key: 5,
          label: `是否只获取电话号码`,
          value: "onlyPhone",
          visible: false,
        },
        {
          key: 6,
          label: `是否电话拒绝以后关闭弹窗`,
          value: "isClosePopWind",
          visible: false,
        },
        {
          key: 7,
          label: `是否使用原生效果页`,
          value: "isUseLdy",
          visible: false,
        },
        { key: 8, label: `原生效果页名称`, value: "ysLdyName", visible: false },
        { key: 9, label: `访问量`, value: "pageviews", visible: true },
      ],

      // 选择的时间
      Time: [],
      // 详细报表对话框
      xxTableBox: false,
      // 详细报表请求数据
      xxTableData: {
        id: "",
        endTime: "",
        startTime: "",
      },
      // 是否有数据
      isDataList: true,

      // 数据导入相关参数
      // 是否显示导入弹出框
      importVisible: false,
      // 上传文件附带其他参数
      otherData: {},
      // 上传文件地址
      uploadFile: window.baseURL + "/comment/importComment",
      // 进度条弹框
      progressOpen: false,
      percent: 0,
      dataListShow: true,
    };
  },
  methods: {
    // 设置首页案例按钮操作
    handlePage(row) {
      this.$refs.setHomeCase.init(row);
    },
    // 复制 -- 投放平台改变时
    handlePutPlat(val) {
      console.log(val);
      if (val == "微信") {
        this.xcxType = [
          {
            label: "数实通科技",
            value: "gh_e111da8f33b8",
          },
          {
            label: "数实通小站",
            value: "gh_d4afa952345c",
          },
        ];
        this.xcxTypeSel = "gh_e111da8f33b8";
      }
       else if (val == "抖音") {
        this.xcxType = [
          {
            label: "线上营销",
            value: "tte17197cc7e49a7a801",
          },
        ];
        this.xcxTypeSel = "tte17197cc7e49a7a801";
      }
    },
    openHomepageCase() {
      let routeData = this.$router.resolve({ name: "homeCase" });
      window.open(routeData.href, "_blank");
    },
    // 自定义列顺序排序的回调方法
    getColumn(row) {
      this.columns = row;
      this.columnsA = [];
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].visible) {
          this.columnsA.push(this.columns[i]);
        }
      }
      this.dataListShow = false;
      setTimeout(() => {
        this.dataListShow = true;
      }, 500);
    },
    // 提交访问量
    submitVisit() {
      this.$refs.SetVisitRef.validate((valid) => {
        if (valid) {
          // 提交访问量
          this.service({
            url: "app/updatePageviews",
            method: "post",
            data: this.SetVisit,
          }).then((res) => {
            if (res.data.code == 1000) {
              this.$message.success("操作成功");
              this.SetVisitBox = false;
              this.getList();
            }
            console.log(res);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    SetVisitBoxClose(done) {
      done();
    },
    // 打开修改访问量对话框
    openSetVisit(row) {
      (this.SetVisit.ldyId = ""),
        (this.SetVisit.ldyId = row.id),
        (this.SetVisitBox = true);
    },
    // 关闭详情报表
    closeBox(done) {
      this.xxTableData = {
        id: "",
        endTime: "",
        startTime: "",
      };
      this.Time = [];
      done();
    },
    // 更改时间
    changeTime(val) {
      console.log(val);
      if (val) {
        console.log(parseTime(val[0], "{y}-{m}-{d}"));
        this.xxTableData.startTime = parseTime(val[0], "{y}-{m}-{d}");
        this.xxTableData.endTime = parseTime(val[1], "{y}-{m}-{d}");
      } else {
        this.xxTableData.startTime = "";
        this.xxTableData.endTime = "";
      }

      this.openXxTableBox(this.xxTableData.id);
    },
    // 打开详情报表
    openXxTableBox(val, type) {
      if (type && type == "初始化") {
        let a = new Date();
        let b = a.getTime();
        let c = b - 86400000 * 7;
        this.Time.push(parseTime(c, "{y}-{m}-{d}"));
        this.Time.push(parseTime(b, "{y}-{m}-{d}"));
        this.xxTableData.startTime = parseTime(c, "{y}-{m}-{d}");
        this.xxTableData.endTime = parseTime(b, "{y}-{m}-{d}");
      }
      this.xxTableData.id = val;
      this.service({
        url: "/ldy/searchChartsById",
        method: "post",
        data: this.xxTableData,
      })
        .then((res) => {
          this.xxTableBox = true;
          console.log(res);
          if (res.data.code == 1000) {
            this.isDataList = true;
            this.$nextTick(() => {
              this.drawPre(res.data.result);
            });
          } else {
            this.isDataList = false;
          }
        })
        .catch((err) => {
          this.isDataList = false;
        });
    },
    // 绘制图表
    drawPre(val) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.myChart);
      // 绘制图表
      myChart.setOption({
        title: {
          text: "数据报表统计",
          subtext: this.xxTableData.startTime
            ? this.xxTableData.startTime + "~" + this.xxTableData.endTime
            : "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "数据报表统计",
            type: "pie",
            radius: "50%",
            data: (function () {
              var res = [];
              for (let i = 0; i < val.length; i++) {
                console.log(val[i]);
                let type = "";
                // if (val[i].type == 1) {
                //   type = "竞品楼盘";
                // }
                // else
                  if (val[i].type == 2) {
                  type = "拨打电话";
                } else if (val[i].type == 3) {
                  type = "商家名片-拨打电话";
                } else if (val[i].type == 4) {
                  type = "商家名片-门店点击";
                } else if (val[i].type == 5) {
                  type = "悬浮组件-按钮点击";
                } else if (val[i].type == 6) {
                  type = "悬浮组件-表单-点击提交";
                } else if (val[i].type == 7) {
                  type = "点击咨询";
                } else if (val[i].type == 8) {
                  type = "点击弹框按钮";
                } else if (val[i].type == 9) {
                  type = "点击底部按钮";
                } else if (val[i].type == 10) {
                  type = "点击提交按钮";
                } else if (val[i].type == 11) {
                  type = "授权电话拒绝";
                } else if (val[i].type == 12) {
                  type = "授权电话接受";
                } else if (val[i].type == 13) {
                  type = "分享";
                }
                res.push({
                  name:
                    type +
                    "次数：" +
                    val[i].num +
                    "次，比例：" +
                    val[i].percent +
                    "%",
                  value: val[i].num,
                });
              }
              return res;
            })(),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    // 显示
    moreShow(val) {
      console.log(val);
      setTimeout(() => {
        for (let i = 0; i < this.moreShowOpen.length; i++) {
          if (i == val) {
            this.moreShowOpen.splice(val, 1, true);
            console.log(this.moreShowOpen);
          } else {
            this.moreShowOpen.splice(i, 1, false);
          }
        }
      }, 200);
    },
    // 隐藏
    moreHide() {
      for (let i = 0; i < this.moreShowOpen.length; i++) {
        this.moreShowOpen.splice(i, 1, false);
      }
    },
    // 跳转落地页管理界面
    ldyManage() {
      console.log(this.$router);
      let routeData = this.$router.resolve({ name: "ldyManage" });
      window.open(routeData.href, "_blank");
    },
    //新增页面中落地页新增跳转页面
    handleAddLdy() {
      let routeData = this.$router.resolve({
        path: "/addLdy",
        query: {
          customerId: this.infoData.customerId,
          type: this.infoData.type,
          state: "xz",
          customerName: this.infoData.customerName,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 打开预览对话框
    onPreviewBox(id, row) {
      this.xcxTypeYlBox = true;
      this.xcxTypeYl = "1";
      this.perPutTypeSel = "小程序";
      this.ylId = id;
      this.dataName = row.ldyName;
      this.getQrCode();
    },
    // 打开SQL弹框
    opSqlBox(con) {
      this.$alert(con, "操作内容", {
        confirmButtonText: "确定",
      });
    },
    // 删除宣传图
    delPic() {
      this.infoData.picUrl = "";
    },
    // 打开操作日志页面
    onOplog() {
      this.oplogBox = true;
      this.getczList();
    },
    // 操作日志列表获取
    getczList() {
      this.service({
        url: "/operatorLog/searchLogs",
        method: "post",
        data: this.cz,
      }).then((res) => {
        this.oplogData = res.data.result.list;
        this.oplogTotal = Number(res.data.result.total);
      });
    },
    // 是否可选
    selectable(row) {
      console.log(row.isDefault);
      if (row.isDefault == 1) {
        return false;
      } else {
        return true;
      }
    },
    // 更改客户类型
    changeKhList(val) {
      this.addkhList = [];
      this.infoData.customerId = "";
      if (val) {
        this.service({
          url: "/ldy/getCustomer",
          method: "post",
          data: {
            type: val,
          },
        }).then((res) => {
          this.addkhList = res.data.result;
        });
      }
    },
    // 客户名称修改
    changeKlName(val) {
      let a = this.addkhList.filter((item) => {
        return item.id == val;
      });
      console.log(a);
      this.infoData.ldyDetailId = undefined;
      this.infoData.customerName = a[0].name;
    },
    // 列表pagesize改变
    handleSizeChange(val) {
      console.log(val);
      this.dataParams.size = val;
      this.getList();
    },
    // 列表pagesize改变
    handleCurrentChange(val) {
      console.log(val);
      this.dataParams.num = val;
      this.getList();
    },
    // 线索列表pagesize改变
    xsSizeChange(val) {
      console.log(val);
      console.log(this.dataName);
      this.xsParams.size = val;
      this.onClue(this.xsParams.ids, this.dataName);
    },
    // 线索列表pagesize改变
    xsCurrentChange(val) {
      console.log(val);
      this.xsParams.num = val;
      console.log(this.dataName);
      this.onClue(this.xsParams.ids, this.dataName, val);
    },
    // 操作日志列表pagesize改变
    czSizeChange(val) {
      console.log(val);
      this.cz.size = val;
      this.getczList();
    },

    // 操作日志列表pagesize改变
    czCurrentChange(val) {
      this.cz.num = val;
      this.getczList();
    },
    // 获取列表
    getList() {
      console.log(this.daterange);
      // if (this.daterange) {
      this.dataParams.startTime = this.daterange ? this.daterange[0] : null;
      this.dataParams.endTime = this.daterange ? this.daterange[1] : null;
      // }
      this.service({
        url: "/ldy/searchLdy",
        method: "post",
        data: this.dataParams,
      }).then((res) => {
        console.log(res.data.result);
        if (res.data.code == 1000) {
          this.tableData = res.data.result.list;
          this.moreShowOpen = [];
          for (let i = 0; i < this.tableData.length; i++) {
            this.moreShowOpen.push(false);
          }
          console.log(this.moreShowOpen);
          this.total = Number(res.data.result.total);
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
      // this.service({
      //   url: "http://192.168.0.116:8054/ygf/app/syPage/getRegion",
      //   method: "get",
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    // 新增
    AddInfo() {
      this.infoData.id = "";
      this.addInfoBox = true;
      this.title = "新增";
      this.infoData.isClosePopWind = 0;
      this.infoData.onlyPhone = 1;
      if (this.infoData.customerName) {
        this.infoData.customerName = "";

        this.$refs["infoData"].resetFields();
      }

      this.$nextTick(() => {
        console.log(213465798);
        for (let i = 0; i < this.anList.length; i++) {
          if (this.anList[i].label == "查看详情") {
            this.infoData.tkButtonId = this.anList[i].id;
          }
          if (this.anList[i].label == "立即咨询") {
            this.infoData.dbButtonId = this.anList[i].id;
          }
          if (this.anList[i].label == "预约登记") {
            this.infoData.tjButtonId = this.anList[i].id;
          }
        }
        console.log(this.infoData.tkButtonId);
      });
      console.log(window.baseURL);
    },
    // 修改
    goSetInfo(row) {
      // console.log("修改row", row);
      this.title = "修改";
      this.ImgList = [];
      this.HeadImageList = [];
      this.addInfoBox = true;
      if (row.isUseLdy == 1) {
        this.handleGetldyList(row.type, row.customerId);
      }
      this.$nextTick(() => {
        this.infoData = JSON.parse(JSON.stringify(row));
        this.infoData = { ...this.infoData, hasDbButton: row.hasDbButton };
        if (row.picUrl) {
          this.ImgList.push({ name: row.picUrl, url: row.picUrl });
        }
        if (row.headImg) {
          this.HeadImageList.push({ name: row.headImg, url: row.headImg });
        }
        // // 客户类型
        // this.infoData.type = Number(this.infoData.type);

        // this.infoData.id = row.id;
        // // 落地页内容
        // this.infoData.content = row.content;
        // // 客户名称
        // this.infoData.customerName = row.customerName;
        // // 弹框按钮
        // this.infoData.tkButtonId = row.tkButtonId;
        // // 底部按钮
        // this.infoData.dbButtonId = row.dbButtonId;
        // // 提交按钮
        // this.infoData.tjButtonId = row.tjButtonId;
        // // 是否只获取手机号
        // this.infoData.onlyPhone = row.onlyPhone.toString();
        // // 落地页名称
        // this.infoData.ldyName = row.ldyName;
        // // 电话
        // this.infoData.phone = row.phone;
        // // 落地页宣传图
        // this.infoData.picUrl = row.picUrl;
        // if (row.picUrl) {
        //   this.ImgList.push({ name: row.picUrl, url: row.picUrl });
        // }
        // // 客户类型
        // this.infoData.type = Number(row.type);
        // // 是否电话拒绝以后关闭弹窗
        // this.infoData.isClosePopWind = row.isClosePopWind.toString();
        // //是否使用落地页1是0否
        // this.infoData.isUseLdy = row.isUseLdy.toString();
        // ////落地页详情
        // this.infoData.ldyDetailId = row.ldyDetailId;

        this.service({
          url: "/ldy/getCustomer",
          method: "post",
          data: {
            type: this.infoData.type,
          },
        }).then((res) => {
          console.log(res);
          this.addkhList = res.data.result;
          // 客户id
          this.infoData.customerId = row.customerId;
        });
      });
    },
    // 删除
    delClick(id, row) {
      let ids;
      if (typeof id == "object") {
        ids = id;
      } else {
        ids = [id];
      }

      this.service({
        url: "/comment/searchComment",
        method: "post",
        data: {
          ldyIds: ids,
        },
      }).then((res) => {
        if (
          res.data.code == 1004 ||
          !res.data.result.total ||
          res.data.result.total == 0
        ) {
          // const info = this.$createElement;
          // this.$msgbox({
          //   title: "确认删除",
          //   type: "warning",
          //   message: info("p", null, [
          //     info("span", { style: "color:red" }, "是否"),
          //     info("span", null, "确认删除?"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          // }).then(() => {
          //   this.delRes(ids);
          // });

          if (row) {
            this.listName = [row.ldyName];
          }
          this.delIds = ids;
          this.delOpen = true;
          this.isUse = false;
        } else {
          // console.log(123456798);
          // // 询问是否删除
          // const info = this.$createElement;
          // this.$msgbox({
          //   title: "确认删除",
          //   type: "warning",
          //   message: info("p", null, [
          //     info(
          //       "span",
          //       { style: "color:red" },
          //       "该条信息包含线索信息，是否"
          //     ),
          //     info("span", null, "确认删除？"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          // }).then(() => {
          //   this.delRes(ids);
          // });
          if (row) {
            this.listName = [row.ldyName];
          }
          this.delIds = ids;
          this.delOpen = true;
          this.isUse = false;
        }
        this.isUse = false;
      });
    },
    // 删除线索方法
    delRes(ids) {
      this.service({
        url: "/ldy/deleteLdyIds",
        method: "post",
        data: {
          ids: this.delIds,
        },
      }).then((resa) => {
        if (resa.data.code == 1000) {
          this.$message.success("删除成功");
          this.delOpen = false;
          this.getList();
        }
      });
    },
    // 新增/修改确定事件
    onAddInfo() {
      console.log(this.infoData);
      this.$refs.infoData.validate((valid) => {
        if (valid) {
          if (this.infoData.isUseLdy == 0) {
            this.infoData.ldyDetailId = null;
          } else {
            this.infoData.content = null;
          }
          this.infoData.updater = null;
          this.service({
            url: "/ldy/addOrEditLdy",
            method: "post",
            data: this.infoData,
          }).then((res) => {
            console.log(res);
            if (res.data.code == 1000) {
              this.addInfoBox = false;
              this.$message.success("操作成功");
              this.getList();
            }
          });
        }
      });
    },
    //新增/修改取消按钮
    cancel() {
      this.addInfoBox = false;
      this.$refs["infoData"].resetFields();
      this.ImgList = [];
      this.HeadImageList = [];
    },
    // 搜索
    handleQuery() {
      console.log(this.daterange);
      this.searchVisible = false;
      this.dataParams.num = 1;
      this.getList();
    },
    czhandleQuery() {
      this.cz.num = 1;
      this.getczList();
    },
    // 重置
    resetQuery(val) {
      //设置查询创建时间字段---默认时间为一周
      this.defaultDate();
      this.dataParams = {
        // 起始页
        num: 1,
        // 页大小
        size: 10,
        // 客户类型0排期1竞价
        type: undefined,
        // 数据ID
        id: undefined,
        // 客户名称
        customerName: undefined,
        // 名称
        ldyName: undefined,
        //起始时间
        startTime: undefined,
        //结束时间
        endTime: undefined,
        // 原生落地页名称
        ysLdyName: undefined,
        // 是否电话拒绝以后关闭弹窗 1是 0否 -1全部
        isClosePopWind: undefined,
        //是否使用落地页1是0否-1全部
        isUseLdy: undefined,
        //是否只获取电话号码1是0否-1全部
        onlyPhone: undefined,
      };
      // this.$refs["queryForm"].resetFields();
      if (val) {
        this.handleQuery();
      }
    },
    czresetQuery() {
      this.cz = {
        operatorName: undefined,
        size: 10,
        num: 1,
      };
      this.czhandleQuery();
    },
    // 线索
    onClue(id, name, num) {
      console.log(id);
      console.log(num);
      console.log(name);

      this.otherData = {
        ldyId: id,
      };

      this.xsParams.ids = id;
      this.dataID = id;
      this.dataName = name;
      if (num) {
        this.xsParams.num = num;
      } else {
        this.xsParams.num = 1;
      }

      console.log(this.xsParams);
      this.service({
        url: "/comment/searchComment",
        method: "post",
        data: {
          num: this.xsParams.num,
          size: this.xsParams.size,
          ldyIds: [id],
        },
      }).then((res) => {
        if (res.data.code == 1000) {
          this.$nextTick(() => {
            this.xsData = res.data.result.list;
            this.xsDataTotal = Number(res.data.result.total);
          });
          console.log(this.xsParams);
        } else {
          this.$nextTick(() => {
            this.xsData = [];
            this.xsDataTotal = 0;
          });
        }
      });
    },

    // 导出
    onExport() {
      if (this.listId.length >= 1) {
        this.service({
          url: "/comment/exportComment",
          method: "post",
          data: {
            ldyId: this.listId,
          },
        }).then((res) => {
          window.open(
            window.baseURL +
              "/ldy/download?fileName=" +
              res.data.msg +
              "&delete=" +
              true
          );
        });
      } else {
        this.service({
          url: "/comment/exportComment",
          method: "post",
          data: {
            ldyId: this.dataParams.id ? [this.dataParams.id] : [],
            customerName: this.dataParams.customerName,
            ldyName: this.dataParams.ldyName,
            type: this.dataParams.type,
          },
        }).then((res) => {
          window.open(
            window.baseURL +
              "/ldy/download?fileName=" +
              res.data.msg +
              "&delete=" +
              true
          );
        });
      }
    },
    // 线索导出
    onxsExport() {
      console.log(this.xsListId);
      this.service({
        url: "/comment/exportComment",
        method: "post",
        data: {
          ldyId: [this.xsParams.ids],
          comId: this.xsListId,
        },
      }).then((res) => {
        console.log(res);
        console.log(
          window.baseURL +
            "/ldy/download?fileName=" +
            res.data.msg +
            "&delete=" +
            true
        );
        window.open(
          window.baseURL +
            "/ldy/download?fileName=" +
            res.data.msg +
            "&delete=" +
            true
        );
      });
    },
    // 线索导入
    handleImport() {
      this.importVisible = true;
    },
    // 多选按钮点击
    handleSelectionChange(val) {
      this.multiple = !val.length;
      this.listId = val.map((item) => item.id);
      this.listName = val.map((item) => item.ldyName);
      console.log(this.listId);
    },
    // 线索按钮点击
    xsSelectionChange(val) {
      this.xsmultiple = !val.length;
      this.xsListId = val.map((item) => item.id);
    },
    // 图片上传成功
    imageListOK(res) {
      console.log(res);
      this.infoData.picUrl = res.result.url;
      this.$refs.lpsyt.clearValidate("sytu");
    },
    // 头像上传成功
    headImageListOK(res) {
      console.log(res);
      this.infoData.headImg = res.result.url;
      this.$refs.lpsytTwo.clearValidate("sytu");
    },
    // 上传前回调
    imageUpBef(file) {
      let name = file.name.split(".");
      if (
        name[name.length - 1].toLowerCase() == "jpg" ||
        name[name.length - 1].toLowerCase() == "png" ||
        name[name.length - 1].toLowerCase() == "bmp" ||
        name[name.length - 1].toLowerCase() == "jpeg"
      ) {
        // return false
        if (file.size / 1024 / 1024 > 10) {
          this.$message.error("图片必须小于10M");
          return false;
        }
      } else {
        this.$message.error("图片格式必须为jpg,png,bmp,jpeg");
        return false;
      }
    },
    // 上传前回调
    headImageUpBef(file) {
      let name = file.name.split(".");
      if (
        name[name.length - 1].toLowerCase() == "jpg" ||
        name[name.length - 1].toLowerCase() == "png" ||
        name[name.length - 1].toLowerCase() == "bmp" ||
        name[name.length - 1].toLowerCase() == "jpeg"
      ) {
        // return false
        if (file.size / 1024 / 1024 > 10) {
          this.$message.error("图片必须小于10M");
          return false;
        }
      } else {
        this.$message.error("图片格式必须为jpg,png,bmp,jpeg");
        return false;
      }
    },
    // 缩影图修改覆盖
    ImgChange(file, fileList) {
      if (fileList.length > 0) {
        this.ImgList = [fileList[fileList.length - 1]];
      }
    },
    // 转发缩影图修改覆盖
    HeadImgChange(file, fileList) {
      if (fileList.length > 0) {
        this.HeadImageList = [fileList[fileList.length - 1]];
      }
    },
    //图片移除回调
    handleRemove() {
      this.infoData.picUrl = "";
    },
    //头像图片移除回调
    headhandleRemove() {
      this.infoData.headImg = "";
    },
    // 打开复制弹框
    onCopy(id, row) {
      console.log(row);
      this.putTypeSel = "小程序";
      this.xcxTypeSel = this.xcxType[0].value;
      this.copyBox = true;
      // this.xcxTypeSel = window.xcxysId;
      this.copyId = id;
      this.dataName = row.ldyName;
    },
    // 线索删除
    xsdelClick(id) {
      let ids;
      if (typeof id == "object") {
        ids = id;
      } else {
        ids = [id];
      }

      const info = this.$createElement;
      this.$msgbox({
        title: "确认删除",
        type: "warning",
        message: info("p", null, [
          info("span", { style: "color:red" }, "是否"),
          info("span", null, "确认删除?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.service({
          url: "/comment/deleteComment",
          method: "post",
          data: {
            ids: ids,
          },
        }).then((res) => {
          if (res.data.code == 1000) {
            this.onClue(this.xsParams.ids, this.dataName);
          }
          // else if (res.data.code == 1004) {
          //   this.xsData = [];
          // }
        });
      });
    },
    // 查询按钮名称
    getBtnLabel() {
      this.service({
        url: "/ldy/getButLabel",
        method: "post",
      }).then((res) => {
        console.log(res);
        this.anList = res.data.result;
      });
    },
    // 查询小程序原始ID
    getFirstId() {
      this.service({
        url: "/app/getYsId",
        method: "POST",
        data: {
          appType: "",
        },
      }).then((res) => {
        this.xcxType[0].value = res.data.result.youkezhiysid;
        this.xcxType[1].value = res.data.result.xsppysid;
        this.xcxTypeSel = res.data.result.youkezhiysid;
      });
    },
    // 获取小程序二维码
    getQrCode() {
      this.loadinga = true;
      this.qrCode = "";
      this.service({
        url: "/app/getUnlimited",
        method: "post",
        responseType: "blob",
        data: {
          appType: this.xcxTypeYl,
          scene: this.ylId,
          // scene:'1434361535285747713'
        },
      }).then((res) => {
        console.log(res.data);
        this.loadinga = false;
        let blob = new Blob([res.data], { type: "image/png" });
        var Fr = new FileReader();
        Fr.readAsDataURL(blob);
        Fr.onload = (event) => {
          this.qrCode = event.target.result; //这个imgurl就是转好的base64的图片格式了
          console.log(this.qrCode);
        };
      });
    },

    //获取原生落地页列表
    handleGetldyList(type, id) {
      console.log(type);
      console.log(id);
      this.service({
        url: "/ldyDetail/getCustomerLdys",
        method: "post",
        data: {
          type: type ? type : this.infoData.type,
          customerId: id ? id : this.infoData.customerId,
        },
      }).then((res) => {
        console.log("通过id和type拿到后端的id", res);
        this.ldyUrlList = [];
        if (res.data.code == 1000) {
          let list = res.data.result;
          list.forEach((item) => {
            this.ldyUrlList.push({
              id: item.id,
              name: item.name,
            });
          });
        }
        console.log(this.ldyUrlList);
      });
    },
    //预览按钮--预览弹出框
    prePage() {
      console.log(this.perPutTypeSel);
      console.log(this.xcxTypeYl);
      if (this.perPutTypeSel == "小程序") {
        this.preCode = true;
        // this.getQrCode();
      } else if (this.perPutTypeSel == "原生页面") {
        console.log("预览原生页面");
        this.isAction = true;
      }
    },
    //设置查询创建时间字段---默认时间为一周
    defaultDate() {
      let end = new Date();
      let start = new Date();
      start.setDate(end.getDate() + -7);
      let month1 = start.getMonth() + 1;
      let date1 = start.getDate();
      let month2 = end.getMonth() + 1;
      let date2 = end.getDate();
      if (month1 >= 1 && month1 <= 9) {
        month1 = "0" + month1;
      }
      if (month2 >= 1 && month2 <= 9) {
        month2 = "0" + month2;
      }
      if (date1 >= 0 && date1 <= 9) {
        date1 = "0" + date1;
      }
      if (date2 >= 0 && date2 <= 9) {
        date2 = "0" + date2;
      }
      start = start.getFullYear() + "-" + month1 + "-" + date1;
      end = end.getFullYear() + "-" + month2 + "-" + date2;

      // start =
      //   start.getFullYear() +
      //   "-" +
      //   (start.getMonth() + 1) +
      //   "-" +
      //   start.getDate();
      // end =
      //   end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();
      this.daterange = [start, end];
    },
    //关闭分享二维码弹框--原生页面
    closeCode() {
      this.isAction = false;
    },
    //改变开关的值为否时清空落地页字段
    handleChangeSwitch(val) {
      // console.log(val);
      // if (val == 0) {
      //   this.dataParams.ldyDetailId = null;
      // }
      // console.log(this.dataParams);
    },

    // 文件上传成功回调
    handleSuccess(res) {
      this.progressOpen = false;
      this.importVisible = false;
      this.$refs.upload.clearFiles();
      if (res.code == 1000) {
        this.$message({
          message: "导入成功",
          type: "success",
        });
        this.xsParams.num = 1;
        this.onClue(this.xsParams.ids, this.dataName);
      } else {
        let info = res.msg || res.error;
        this.$alert(info, "导入结果", { dangerouslyUseHTMLString: true });
      }
    },
    // 文件上传前回调
    handleBefore(file) {
      if (file.size / 1024 / 1024 > 20) {
        this.$message.error("文件必须小于20M");
        return false;
      }
      this.progressOpen = true;
    },
    // 文件上传时
    hanldeProgress(event) {
      this.percent = Math.floor(event.percent);
    },
    // 模板下载
    handleTemplate() {
      this.service({
        url: "/comment/downLoadTemp",
        method: "post",
        responseType: "blob",
      }).then((res) => {
        var blob = new Blob([res.data], { type: "application/vnd.ms-excel" }); // type这里表示xlsx类型
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        let a = parseTime(new Date(), "{y}{m}{d}{h}{i}{s}");
        downloadElement.download = "线索模板-" + a + ".xls"; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
      });
    },
    // 导入按钮操作
    submitFileForm() {
      this.$refs.upload.submit();
    },
    // 取消按钮操作
    fileCancel() {
      this.importVisible = false;
      this.$refs.upload.clearFiles();
    },
  },
  created() {
    console.log(process.env.VUE_APP_BASE_URL);
    //设置查询创建时间字段---默认时间为一周
    this.defaultDate();
    this.getList();
    this.getBtnLabel();
    this.getFirstId();
    this.show = window.sessionStorage.getItem("show");
    if (this.show) {
      window.location.href = "http://www.baidu.com";
    }
    //获取localStorage中的自定义列信息
    let storeColumns = JSON.parse(
      window.localStorage.getItem("NewstoreColumns")
    );
    if (storeColumns && storeColumns.dataInfo) {
      if (storeColumns.dataInfo.length == this.columns.length) {
        this.columns = storeColumns.dataInfo;
      } else {
        for (let i = 0; i < storeColumns.dataInfo.length; i++) {
          for (let j = 0; j < this.columns.length; j++) {
            if (this.columns[j].label == storeColumns.dataInfo[i].label) {
              this.columns[j].visible = storeColumns.dataInfo[i].visible;
            }
          }
        }
      }
    }
    this.columnsA = [];
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].visible) {
        this.columnsA.push(this.columns[i]);
      }
    }
  },
};
</script>
<style scoped>
.box {
  background: #fff;
  width: 100%;
  border-radius: 16px;
  padding: 25px;
  box-sizing: border-box;
}

/* .box /deep/ .el-dialog__body {
  padding: 0 20px 30px 20px;
} */
.btn {
  margin-bottom: 12px !important;
}

.input_con {
  height: 32px;
}

.input-box {
  position: relative;
}

.input-box span {
  position: absolute;
  right: 5px;
  font-size: 12px;
  color: #c0c4cc;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.el-pagination {
  text-align: right;
  margin-top: 12px;
}

.czCap {
  margin-bottom: 12px;
}

.czCap .sea-btn {
  margin-left: 12px;
}

.del {
  position: absolute;
  top: 0px;
  font-size: 24px;
  right: 0px;
  z-index: 999;
}

.cz-con {
  max-width: 388px !important;
  word-break: break-all;
}

.qrcode {
  width: 200px;
  height: 200px;
  position: absolute;
  border: 1px solid #e2e4e8;
  border-radius: 8px;
  overflow: hidden;
  top: 40px;
  left: -80%;
  display: none;
}

.qrcode img {
  width: 100%;
  height: 100%;
}

.ldyyl:hover .qrcode {
  display: block;
}

.ldyyl {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  background: #f7f9fc;
  font-size: 12px;
  padding: 6px 8px;
  box-sizing: border-box;
  height: 32px;
  line-height: 20px;
  color: #1a1a1a;
  border-radius: 4px;
  cursor: pointer;
}

.form_box /deep/ .el-form-item__label {
  padding-right: 0px;
  text-align: left;
}

.form_box /deep/ .el-icon-warning-outline {
  margin-right: 12px;
}

.form_box /deep/ .el-form-item__error {
  margin-left: 26px;
}

.box /deep/ .el-date-editor .el-range-separator {
  width: 10%;
}

.tablescope /deep/ .el-switch__label--left {
  position: relative;
  left: 45px;
  color: #fff;
  z-index: -1111;
}

.tablescope /deep/ .el-switch__core {
  width: 50px !important;
}

.tablescope /deep/ .el-switch__label--right {
  position: relative;
  right: 46px;
  color: #fff;
  z-index: -1111;
}

.tablescope /deep/ .el-switch__label--right.is-active {
  z-index: 1111;
  color: #fff !important;
}

.tablescope /deep/ .el-switch__label--left.is-active {
  z-index: 1111;
  color: #9c9c9c !important;
}

.popCon {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.codeBox {
  width: 300px;
  height: 300px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}

.mess_attention {
  font-size: 16px;
  color: #3d3d3d;
  margin-top: 20px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}

.close {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.codeBox i {
  font-size: 22px;
  color: #000;
}

.popCon img {
  width: 200px;
  height: 200px;
}

.ldyadd {
  margin-left: 30px;
  color: #0000ffd8;
  cursor: pointer;
}

.box >>> .el-popover {
  min-width: 100px;
}

.table-box >>> .el-table__row--striped td {
  background-color: #f7f9fc !important;
}

.btn-a {
  display: flex;
  justify-content: center;
  margin-left: 7px;
  margin-top: 10px;
  width: 95px;
}

.zwsj {
  text-align: center;
  line-height: 600px;
  height: 600px;
}
</style>
<style>
.del_box .el-dialog__header {
  border-bottom: none !important;
}

.del_box .el-dialog__footer {
  border-top: none !important;
  padding: 20px;
  padding-top: 10px;
}

.title_box {
  display: flex;
  align-items: center;
}

.title_box img {
  width: 20px;
  margin-right: 6px;
}
</style>