import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 当前选择的元素
    element:undefined,
    // 图片元素更新
    imageEl:0,
  },
  mutations: {
    ImageUpData(state){
      console.log('图片更新了');
      state.imageEl = state.imageEl + 1
    },
    changeElement(state,a){
      state.element = a
    }
  },
  actions: {
  },
  modules: {
  }
})
