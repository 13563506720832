import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'


// 创建axios实例
const service = axios.create({
  baseURL:  window.baseURL, 
  timeout: 600000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // 未设置状态码则默认成功状态
    const res = response.data || {} ;
    if (res.error) {
      Message({
        message: res.error,
        type: 'error'
      })
      // return Promise.reject('error')
    } else if (res.warning) {
      Message({
        message: res.warning,
        type: 'warning'
      })
      // return Promise.resolve(response)
    }

    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    console.log('err' + error)// for debug
    return Promise.reject(error)
  })

export default service