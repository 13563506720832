<template>
  <div>
    <el-dialog
      :visible.sync="open"
      width="410px"
      @close="cancel"
      :close-on-click-modal="false"
    >
      <div slot="title" class="title_box">
        <img
          src="@/assets/icon/setHome.png"
          alt=""
          style="width: 20px; margin-right: 2px"
        />
        <span style="color: #0000ffd8">设置首页案例</span>
      </div>
      <el-row v-if="tips">
        <el-col>
          <p style="margin-top: -15px; color: #00000072">
            {{ tips }}
          </p>
        </el-col>
      </el-row>

      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="form_box"
        label-width="70px"
        label-position="left"
      >
        <!-- :value="`${item.name},${item.id}`" -->
        <el-form-item label="平台：" prop="mediaId">
          <el-select
            v-model="form.mediaId"
            clearable
            filterable
            placeholder="请选择"
            style="width: 300px"
            @change="handleChange"
          >
            <el-option
              v-for="item in platOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- maxlength="400"
            show-word-limit -->
        <el-form-item label="类别：" prop="category">
          <el-input
            v-model="form.category"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="cancel">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="handleSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import request from "@/utils/request";
import { parseTime } from "@/assets/js/formatTime";
export default {
  data() {
    return {
      // 遮罩层 -- 按钮
      btnLoading: false,
      // 是否显示对话框
      open: false,
      // 面包屑
      tips: "",
      // 平台下拉选项列表
      platOptions: [],

      // 表单参数
      form: {
        // 落地页数据id
        ldyId: "",
        // 类别
        category: "",
        // 媒体平台id
        mediaId: "1",
      },
      // 表单验证
      rules: {
        mediaId: [{ required: true, message: "请选择平台", trigger: "change" }],
        category: [{ required: true, message: "请填写类别", trigger: "blur" }],
      },
      // 用户已设置首页案例列表
      caseList: [],
    };
  },
  created() {
    /* 获取媒体平台列表 */
    this.service({
      url: "/ldy/getMediaPlatform",
      method: "get",
    })
      .then((res) => {
        console.log(res);
        this.platOptions = [];
        if (res.data.code == 1000) {
          this.platOptions = res.data.result;
        }
      })
      .catch(() => {
        this.platOptions = [];
      });
  },
  methods: {
    // 初始化数据
    async init(data) {
      await this.service({
        url: "/ldy/echoCase",
        method: "get",
        params: {
          ldyId: data.id,
        },
      }).then((res) => {
        console.log(res);
        this.caseList = [];
        if (res.data.result.length > 0) {
          this.caseList = JSON.parse(JSON.stringify(res.data.result));
          this.form = JSON.parse(JSON.stringify(res.data.result[0]));
        } else {
          this.form.ldyId = data.id;
        }
      });
      this.open = true;
      this.tips = data.ldyName;
    },

    // 平台改变时 清空数据/回显对应媒体数据
    handleChange(val) {
      let obj = this.caseList.find((item) => {
        return item.mediaId === val;
      });
      if (obj) {
        this.form = JSON.parse(JSON.stringify(obj));
      } else {
        console.log(this.form);
        this.form.id = "";
        this.form.category = "";
      }
    },

    //  取消按钮操作
    cancel() {
      this.open = false;
      this.form = {
        // 落地页数据id
        ldyId: "",
        // 类别
        category: "",
        // 媒体平台id
        mediaId: "1",
      };
      this.$refs["form"].resetFields();
    },
    // 表单提交
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let data = JSON.parse(JSON.stringify(this.form));
          this.service({
            url: "/ldy/setHomepageCase",
            method: "post",
            data: data,
          })
            .then((res) => {
              this.btnLoading = false;
              if (res.data.code == 1000) {
                this.open = false;
                this.$message.success("操作成功");
                // this.$parent.getList();
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.title_box {
  display: flex;
  align-items: center;
}
/* .form_box {
  height: 60vh;
  overflow: auto;
} */
</style>
