import Vue from 'vue'
import VueRouter from 'vue-router'
import { MessageBox } from 'element-ui'
Vue.prototype.$message = MessageBox
Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  // 首页导航
  {
    path: '/',
    name: 'table',
    component: () => import("@/view/nav.vue"),
  },
  // 首页导航
  {
    path: '/login',
    name: 'login',
    component: () => import("@/view/login/login.vue"),
  },
  // 数据管理
  {
    path: '/data',
    name: 'data',
    component: () => import('../components/table.vue'),
  },
  // 顶部轮播图及广告位
  {
    path: '/topData',
    name: 'topData',
    component: () => import('@/view/topData/index.vue'),
  },
  // 产品管理
  {
    path: '/core',
    name: 'core',
    component: () => import('@/view/core/index.vue'),
  },
  // 行业解决方案
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/view/solution/index.vue'),
  },
  // 首页案例
  {
    path: '/homeCase',
    name: 'homeCase',
    component: () => import('@/view/homeCase/index.vue'),
  },
  // 落地页管理
  {
    path: '/ldyManage',
    name: 'ldyManage',
    component: () => import('../view/ldyManage.vue'),
  },
  // 落地页创造页面
  {
    path: '/addLdy',
    name: 'addLdy',
    component: () => import('../view/addLdy.vue'),
  },
  // 预览页面
  {
    path: '/previewPage',
    name: 'previewPage',
    component: () => import('../view/previewPage.vue'),
  },
  // 展示页面
  {
    path: '/showPage',
    name: 'showPage',
    component: () => import('../view/showPage.vue'),
    meta: {
      title: "原生效果页"
    }
  },
  // 高级表单
  {
    path: '/advancedForms',
    name: 'advancedForms',
    component: () => import('@/view/advancedForms/index.vue'),
    // meta: {
    //   title: "高级表单"
    // }
  }
]
const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/clue/'
})
router.beforeEach((to, from, next) => {
  // 根据路由元信息设置文档标题
  window.document.title = to.meta.title || '起量通'
  // 根据路由name判断是否进主页，并判断用户是否登录
  if (to.name != 'login') {
    // let tempUserToken = localStorage.getItem('useTokenRouter');
    let tempUserToken = sessionStorage.getItem('useTokenRouter');
    if (tempUserToken == 'LoginTrue') {
      next()
    } else {
      let isTo = false;
      let tempOtherTo = to.fullPath.split("&");
      tempOtherTo.forEach(item => {
        let newArray = item.split("=");
        if (newArray[0] == "customerId" || newArray[0] == "type") {
          isTo = true;
          // localStorage.setItem("useTokenRouter", 'LoginTrue');
          sessionStorage.setItem("useTokenRouter", 'LoginTrue');
          next()
        }
      })

      // 针对预览页面传参与上一个方法传递的值不同增加判断条件
      let tempIdTo = to.fullPath.split("&");

      if (isTo) return;
      Vue.prototype.$message({
        message: '未登录，已为你跳转登录界面',
        type: 'warning',
        center: true
      });
      next({ path: '/login' });
    }
  } else {
    next();
  }
  next()
})
export default router