import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueQuillEditor from 'vue-quill-editor'
// vue-quill-editor 样式引入
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//修改表格全选默认样式
import './assets/styles/element-sele.css'
import './assets/styles/common.css'
// 引入axios
import axios from 'axios'
import service from './network'
// 引入vuex
import store from './store/index.js'
import Vuex from 'vuex'
// 引入vant ui
import Vant from 'vant'
import 'vant/lib/index.css';
// 引入复制插件
import VueClipBoard from 'vue-clipboard2'
//自定义表格工具
import RightToolbar from './components/RightToolbar/index.vue'
import NewRightToolbar from "@/components/NewRightToolbar"

// import i18n from "@/i18n";
// 引入echarts
import * as echarts from 'echarts'

import Pagination from "./components/Pagination/index.vue";
// 全局组件挂载
Vue.component('Pagination', Pagination)


//全局组件挂载
Vue.component('RightToolbar', RightToolbar)
Vue.component('NewRightToolbar', NewRightToolbar)

Vue.use(VueClipBoard);
Vue.use(Vant)
// Vue.use(Element, {
//   size: "default",
//   i18n: (key, value) => i18n.t(key, value),
// });
Vue.use(Vuex)
Vue.use(VueQuillEditor)
Vue.use(Element)
Vue.use(axios)
Vue.prototype.$echarts = echarts
Vue.prototype.service = service
Vue.config.productionTip = false

new Vue({
  // i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
